import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";

export const HIGHLIGHT_SECTION = {
  HOW_IT_WORKS: "HOW_IT_WORKS",
  ANNOTATE: "ANNOTATE",
  AUTOMATE: "AUTOMATE",
  EVALUATE: "EVALUATE",
  MANAGE: "MANAGE",
};

export default function HomeNavigation({ highlight }) {

  const getClassName = (section) => {
    if (section === highlight) {
      return "inline-flex text-center text-gray-100 py-2 px-4 rounded-full bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out";
    }

    return "inline-flex text-center text-gray-800 py-2 px-4 rounded-full bg-white hover:bg-gray-100 shadow-sm transition duration-150 ease-in-out";
  };

  return (
    <ul className="flex flex-wrap justify-center text-sm font-medium -m-2">
      <li className="m-2">
        <AnchorLink
          className={getClassName(HIGHLIGHT_SECTION.HOW_IT_WORKS)}
          to="#modus"
        >
          How it works
        </AnchorLink>
      </li>
      <li className="m-2">
        <AnchorLink
          className={getClassName(HIGHLIGHT_SECTION.ANNOTATE)}
          to="#annotate"
        >
          Annotate
        </AnchorLink>
      </li>
      <li className="m-2">
        <AnchorLink
          className={getClassName(HIGHLIGHT_SECTION.AUTOMATE)}
          to="#automate"
        >
          Automate
        </AnchorLink>
      </li>
      <li className="m-2">
        <AnchorLink
          className={getClassName(HIGHLIGHT_SECTION.EVALUATE)}
          to="#evaluate"
        >
          Evaluate
        </AnchorLink>
      </li>
      <li className="m-2">
        <AnchorLink
          className={getClassName(HIGHLIGHT_SECTION.MANAGE)}
          to="#manage"
        >
          Manage
        </AnchorLink>
      </li>
    </ul>
  );
}
