import React from "react";

export default function StandardSectionHeader({ title, header, description }) {
  return (
    <div className="pb-8 md:pb-8">
      <div className="font-architects-daughter text-xl bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-400 mb-2">
        {title}
      </div>
      <h2 className="h2 mb-4">
        <span>{header}</span>
      </h2>
      <p className="text-xl text-gray-600">{description}</p>
    </div>
  );
}
