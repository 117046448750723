import React from "react";
import StandardSectionHeader from "./StandardSectionHeader";
import HomeNavigation from "../../partials/HomeNavigation";
import CustomLink from "../../components/CustomLink";

export default function TwoFeatureOverview({
  title,
  highlight,
  imageOne,
  imageTwo,
  imageThree,
  imageFour,
  header,
  headerOne,
  headerTwo,
  headerThree,
  headerFour,
  description,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
  descriptionFour,
  includeHeader,
  includeTopBorder,
  includeTopPadding,
  includeGrayTransition,
  learnMoreOne,
  learnMoreTwo,
  learnMoreThree,
  learnMoreFour,
  learnMoreOneText,
  learnMoreTwoText,
  learnMoreThreeText,
  learnMoreFourText,
}) {
  const getSectionStyle = () => {
    if (includeGrayTransition) {
      return "relative bg-gradient-to-b from-gray-100 to-white border-t border-gray-200";
    }

    if (includeTopBorder) {
      return "border-t border-gray-200";
    }

    return "";
  };

  return (
    <section className={getSectionStyle()}>
      <div
        className={
          includeTopPadding
            ? "max-w-6xl mx-auto px-4 sm:px-6 pb-10 pt-10"
            : "max-w-6xl mx-auto px-4 sm:px-6 pb-10"
        }
      >
        <div>
          {highlight && (
            <div className="mb-12 md:mb-12">
              <HomeNavigation highlight={highlight} />
            </div>
          )}

          {includeHeader && (
            <StandardSectionHeader
              title={title}
              header={header}
              description={description}
            />
          )}

          <div className="grid gap-20">
            <div className="grid gap-20">
              {imageOne && (
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                  {/* Image */}
                  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 text-center flex justify-center items-center">
                    {imageOne}
                    {/*    base size 1800x1000 can then scale to 800w */}
                  </div>
                  {/* Content */}
                  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                    <div className="md:pr-4 lg:pr-12 xl:pr-16">
                      <h3 className="h3 mb-3">{headerOne}</h3>
                      <p className="text-xl text-gray-400 mb-4">
                        {descriptionOne}
                      </p>
                      {learnMoreOne && (
                        <div className="flex justify-end items-end">
                          <CustomLink
                            to={learnMoreOne}
                            className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                          >
                            <span>
                              {learnMoreOneText
                                ? learnMoreOneText
                                : "Learn more"}
                            </span>
                            <svg
                              className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                              viewBox="0 0 12 12"
                              xmlns="https://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                fillRule="nonzero"
                              />
                            </svg>
                          </CustomLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {imageTwo && (
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                  {/* Image */}
                  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl">
                    {imageTwo}
                  </div>
                  {/* Content */}
                  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                      <h3 className="h3 mb-3">{headerTwo}</h3>
                      <p className="text-xl text-gray-400 mb-4">
                        {descriptionTwo}
                      </p>
                      {learnMoreTwo && (
                        <div className="flex justify-end items-end">
                          <CustomLink
                            to={learnMoreTwo}
                            className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                          >
                            <span>
                              {learnMoreTwoText
                                ? learnMoreTwoText
                                : "Learn more"}
                            </span>
                            <svg
                              className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                              viewBox="0 0 12 12"
                              xmlns="https://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                fillRule="nonzero"
                              />
                            </svg>
                          </CustomLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {imageThree && (
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                  {/* Image */}
                  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 text-center flex justify-center items-center">
                    {imageThree}
                    {/*    base size 1800x1000 can then scale to 800w */}
                  </div>
                  {/* Content */}
                  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                    <div className="md:pr-4 lg:pr-12 xl:pr-16">
                      <h3 className="h3 mb-3">{headerThree}</h3>
                      <p className="text-xl text-gray-400 mb-4">
                        {descriptionThree}
                      </p>
                      {learnMoreThree && (
                        <div className="flex justify-end items-end">
                          <CustomLink
                            to={learnMoreThree}
                            className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                          >
                            <span>
                              {learnMoreThreeText
                                ? learnMoreThreeText
                                : "Learn more"}
                            </span>
                            <svg
                              className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                              viewBox="0 0 12 12"
                              xmlns="https://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                fillRule="nonzero"
                              />
                            </svg>
                          </CustomLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {imageFour && (
                <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
                  {/* Image */}
                  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:rtl">
                    {imageFour}
                  </div>
                  {/* Content */}
                  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6">
                    <div className="md:pl-4 lg:pl-12 xl:pl-16">
                      <h3 className="h3 mb-3">{headerFour}</h3>
                      <p className="text-xl text-gray-400 mb-4">
                        {descriptionFour}
                      </p>
                      {learnMoreFour && (
                        <div className="flex justify-end items-end">
                          <CustomLink
                            to={learnMoreFour}
                            className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out"
                          >
                            <span>
                              {learnMoreFourText
                                ? learnMoreFourText
                                : "Learn more"}
                            </span>
                            <svg
                              className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                              viewBox="0 0 12 12"
                              xmlns="https://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                fillRule="nonzero"
                              />
                            </svg>
                          </CustomLink>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
