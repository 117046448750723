import React from "react";
import LeadFormWithAPI from "../../components/LeadFormWithAPI";
import CustomLink from "../../components/CustomLink";

function StandardHeaderOneColumn(props) {
  const { title, description, hideDemoRequest, heroGraphic } = props;
  return (
    <section>
      <div className="max-w-6xl px-4 mx-auto sm:px-6">
        <div className="pt-32 md:pt-40">
          {/* Hero content */}
          <div className="pt-20 pb-12 text-center md:pb-16">
            <h1 className="relative mb-4 font-extrabold h1 lg:text-5xl font-red-hat-display z-1">
              {title}
            </h1>
            <div className="max-w-3xl mx-auto">
              <p className="relative text-xl text-gray-600 dark:text-gray-400">
                {description}
              </p>

              {!hideDemoRequest && (
                <div className="mt-8">
                  <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <LeadFormWithAPI
                      pageName={props?.pageName}
                      location={props?.location}
                      btnText="Book a demo"
                    />
                  </div>
                </div>
              )}

              <p className="mt-2 text-lg italic text-gray-600 dark:text-gray-400">
                Part of an academic institution?{" "}
                <CustomLink
                  target="_blank"
                  className="text-purple-400 underline"
                  to="/academic-application/"
                >
                  Apply for access here.
                </CustomLink>
              </p>
              {heroGraphic && (
                <div>
                  <div className="relative flex justify-center mt-12">
                    <div className="flex flex-col justify-center">
                      {heroGraphic}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StandardHeaderOneColumn;
